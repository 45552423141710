import React, { useEffect, useRef } from "react";
import { GalleryImageProps } from "./gallery-image.props";
import PinchZoom from "pinch-zoom-js";

const GalleryImage: React.FC<GalleryImageProps> = ({ src, alt, title, loading, index }) => {
    const ref = useRef<HTMLInputElement>(null);
    const initZoom = () => {
        if (ref.current) {
            let el = ref.current as HTMLElement;
            const height = el.offsetHeight;

            if (el && height > 0) {
                /* eslint-disable no-new */
                new PinchZoom(el, {
                    draggableUnzoomed: false,
                    minZoom: 1
                });
            }
        }
    };

    useEffect(() => {
        initZoom();
    }, [ref, index]);

    return (
        <div className="image-gallery-image-cont" ref={ref}>
            <img
                className="image-gallery-image"
                src={src}
                alt={alt}
                title={title}
                loading={loading}
            />
        </div>
    );
};

export default GalleryImage;