import React, { FC } from "react";
import { IconProps } from "@shared/ui/Icon/ui/types";

export const TelegramFilledIcon: FC<IconProps> = ({ className }) => (
    <svg
        className={className}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.8002 11.9999C22.8002 17.9646 17.9648 22.7999 12.0002 22.7999C6.03558 22.7999 1.2002 17.9646 1.2002 11.9999C1.2002 6.03527 6.03558 1.19995 12.0002 1.19995C17.9648 1.19995 22.8002 6.03527 22.8002 11.9999ZM14.5562 17.4124L10.2037 14.1968L15.2119 9.6775C15.4316 9.48238 15.1637 9.38733 14.8719 9.56427L8.69146 13.4635L6.02169 12.6302C5.44531 12.4537 5.44109 12.0575 6.15124 11.7727L16.5543 7.76131C17.0295 7.54558 17.4881 7.87552 17.3067 8.60271L15.535 16.9514C15.4112 17.5446 15.053 17.6865 14.5562 17.4124Z"
            fill="white"
        />
    </svg>
);