import React from "react";
import classes from "./callback-form-button.module.scss";
import SupportIcon from "@shared/ui/Icon/ui/SupportIcon/SupportIcon";
import CBIconClasses from "@shared/ui/Icon/ui/SupportIcon/SupportIcon.module.scss";
import { showCallbackForm } from "@app/store/layoutSlice";
import { useDispatch } from "react-redux";

const CallbackFormButton: React.FC = () => {
    const [isHovered, setIsHovered] = React.useState(false);
    const dispatch = useDispatch();
    const openForm = () => {
        dispatch(showCallbackForm());
    };
    return (
        <div
            onClick={openForm}
            className={classes.CBButton}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <SupportIcon className={isHovered ? CBIconClasses.Hovered : ""} />
        </div>
    );
};

export default CallbackFormButton;