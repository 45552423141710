import React, { FC } from "react";
import { IconProps } from "@shared/ui/Icon/ui/types";

export const VkFilledIcon: FC<IconProps> = ({ className }) => (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            d="M23.4492 5.765c.1669-.5564 0-.9652-.794-.9652h-2.6256c-.6676 0-.9753.3531-1.1423.7426 0 0-1.3352 3.2545-3.2267 5.3685-.6119.612-.8901.8067-1.2239.8067-.1669 0-.4085-.1947-.4085-.751V5.7649c0-.6676-.1937-.9651-.7501-.9651h-4.126c-.4172 0-.668.3098-.668.6035 0 .6329.9456.7788 1.0431 2.5591v3.8665c0 .8477-.153 1.0014-.4869 1.0014-.89 0-3.0552-3.269-4.3394-7.0097-.2516-.7271-.504-1.0208-1.175-1.0208H.9001C.15 4.7998 0 5.1529 0 5.5424c0 .6954.8901 4.1446 4.1446 8.7065 2.1697 3.1154 5.2266 4.8042 8.0082 4.8042 1.669 0 1.8754-.3751 1.8754-1.0212v-2.3546c0-.7502.1582-.8999.6867-.8999.3894 0 1.057.1947 2.6147 1.6968 1.7803 1.7803 2.0738 2.5789 3.0751 2.5789h2.6256c.7502 0 1.1253-.3751.9089-1.1153-.2368-.7377-1.0868-1.8081-2.2146-3.0769-.612-.7233-1.5299-1.5021-1.8081-1.8916-.3894-.5006-.2781-.7232 0-1.1682 0 0 3.1989-4.5063 3.5327-6.0362Z"
            clipRule="evenodd"
        />
    </svg>
);