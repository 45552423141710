import React, { useEffect, useState } from "react";
import classNames from "classnames";
import {
    useAddOfferToComparisonMutation,
    useGetCompareOffersQuery,
    useRemoveOfferFromComparisonMutation
} from "@entities/compare";
import { CompareIcon } from "@shared/ui/Icon/ui/Common/CompareIcon";
import { CompareToggleProps } from "./types";
import classes from "./CompareToggle.module.scss";

export const CompareToggle = ({
    offerId,
    isAuth,
    className,
    classNameIcon,
    classNameIconActive
}: CompareToggleProps) => {
    const { data: compareData } = useGetCompareOffersQuery(undefined, {
        skip: !isAuth
    });
    const [addOfferToComparison, { isLoading: isAdding }] = useAddOfferToComparisonMutation();
    const [removeOfferFromComparison, { isLoading: isRemoving }] =
        useRemoveOfferFromComparisonMutation();

    const [isComparison, setIsComparison] = useState(false);

    const canToggleComparison = !isAdding && !isRemoving && offerId;

    const toggleComparison = async (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        if (!canToggleComparison) return;

        const prevComparisonState = isComparison;
        setIsComparison(!isComparison);

        try {
            if (!prevComparisonState) {
                await addOfferToComparison({ offerUuid: offerId }).unwrap();
            } else {
                await removeOfferFromComparison({ offerId }).unwrap();
            }
        } catch (error) {
            setIsComparison(prevComparisonState);
        }
    };

    useEffect(() => {
        if (compareData) {
            setIsComparison(compareData.offers.some((offer) => offer.id === offerId));
        }
    }, [compareData, offerId]);

    return (
        <div className={className} onClick={toggleComparison}>
            <CompareIcon
                className={classNames(classNameIcon || classes["compare-toggle"], {
                    [classNameIconActive || classes["compare-toggle__active"]]: isComparison
                })}
            />
        </div>
    );
};